import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store";
// //import i18n from "@/i18n";
// import NProgress from "nprogress";
import "nprogress/nprogress.css";

// Containers
// import TheContainer from "@/containers/TheContainer";
//
// import Auth from "./auth.js";
// import Users from "./users.js";
// import Roles from "./roles.js";
// import Companies from "./companies.js";
// import Websites from "./websites.js";
// import Keywords from "./keywords.js";
// import Segments from "./segments.js";
// import AdvertiserAuction from "./advertiser_auction.js";
// import PublisherAuction from "./publisher_auction.js";
// import Settings from "./settings.js";
// import Translations from "./translations.js";
// import Interests from "./interests.js";
// import Finance from "./finance.js";
// import Support from "./support.js";
// import Admin from "./admin.js";
// import Bid from "./bid.js";
// // Version 2.0
// import AdvertiserOffers from "../router/v_2/advertiser_offers.js";
// import AdvertiserOrders from "../router/v_2/advertiser_orders.js";
// import AdvertiserWishlist from "../router/v_2/advertiser_wishlist.js";
// import PublisherOrders from "../router/v_2/publisher_orders.js";
// import PublisherOffers from "../router/v_2/publisher_offers.js";
// import PublisherWishlist from "../router/v_2/publisher_wishlist.js";
// import AdminWishlist from "../router/v_2/admin_wishlist.js";
import EndView from "@/views/EndView.vue";

// let frontRoutes = [];
// let adminRoutes = [];
//
// //iframe front - routes
// frontRoutes = frontRoutes.concat(Auth[0].data.front); //Auth
//
// //admin - routes
// adminRoutes = adminRoutes.concat(Auth[0].data.admin); //dashboard
// adminRoutes = adminRoutes.concat(Users[0].data.admin); //Users
// adminRoutes = adminRoutes.concat(Roles[0].data.admin); //Roles
// adminRoutes = adminRoutes.concat(Companies[0].data.admin); //Companies
// adminRoutes = adminRoutes.concat(Websites[0].data.admin); //Websites
// adminRoutes = adminRoutes.concat(Keywords[0].data.admin); //Keywords
// adminRoutes = adminRoutes.concat(Segments[0].data.admin); //Segments
// adminRoutes = adminRoutes.concat(AdvertiserAuction[0].data.admin); //Advertiser/auction
// adminRoutes = adminRoutes.concat(PublisherAuction[0].data.admin); //Publisher/auction
// adminRoutes = adminRoutes.concat(Settings[0].data.admin); //Settings
// adminRoutes = adminRoutes.concat(Translations[0].data.admin); //Translations
// adminRoutes = adminRoutes.concat(Interests[0].data.admin); //Interests
// adminRoutes = adminRoutes.concat(Finance[0].data.admin); //Finance
// adminRoutes = adminRoutes.concat(Support[0].data.admin); //Finance
// adminRoutes = adminRoutes.concat(Admin[0].data.admin); //Admin
// adminRoutes = adminRoutes.concat(Bid[0].data.admin); //Admin
// // Version 2.0
// adminRoutes = adminRoutes.concat(AdvertiserOffers[0].data.admin); //Admin
// adminRoutes = adminRoutes.concat(AdvertiserOrders[0].data.admin); //Admin
// adminRoutes = adminRoutes.concat(PublisherOrders[0].data.admin); //Admin
// adminRoutes = adminRoutes.concat(PublisherOffers[0].data.admin); //Admin
// adminRoutes = adminRoutes.concat(AdvertiserWishlist[0].data.admin); //Advertiser Wishlist
// adminRoutes = adminRoutes.concat(PublisherWishlist[0].data.admin); //Publisher Wishlist
// adminRoutes = adminRoutes.concat(AdminWishlist[0].data.admin); //Publisher Wishlist

const merge = [
  {
    path: "/",
    name: "endView",
    component: EndView
  }, {
    path: '*',
    redirect: '/'
  }
];

Vue.use(VueRouter);

// const routes = frontRoutes.concat(merge);

const router = new VueRouter({
  mode: "history",
  routes: merge
});


// router.beforeResolve(function(to, from, next) {
//   if (to.name) {
//     NProgress.start();
//   }
//   next();
// });

// router.afterEach(() => {
//   NProgress.done();
//
//   if(store.getters["auth/authenticated"] === true) {
//     store.dispatch('credit/refreshCredit');
//   }
// });

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

  //   if (!store.getters["auth/authenticated"]) {
  //     next({
  //       path: "/auth/sign-in"
  //     });
  //   } else {
  //     if(store.getters["auth/user"].verified === false && (to.path !== '/auth/not-verified' && to.path !== '/auth/verify'))
  //     {
  //       next({path: '/auth/not-verified'})
  //     }
  //     if(store.getters["auth/user"].verified === true && to.path === '/auth/not-verified')
  //     {
  //       next({path: '/'+ store.state.view.status +'/dashboard'})
  //     }
  //     if (
  //       store.state.view.status === "publisher" &&
  //       to.path === "/publisher/dashboard"
  //     ) {
  //       next({ path: "/publisher/orders" });
  //     }
  //
  //     next();
  //   }
  // } else {
  //   if (store.getters["auth/authenticated"] && !to.meta.nonRedirect) {
  //     next({
  //       path: "dashboard"
  //     });
  //   }
//     next(); // make sure to always call next()!
//   }
// });

export default router;
