/* eslint-disable */
<template>
  <div>
    <div style="text-align:center;" class="py-3 top-bar">
      <img
          class="addmention-logo-sidebar"
          src="/svg/logo_addmention.svg"
          alt="AddMention"
      />


      <div class="center-screen">
        <div>
        Ak máte záujem o spätné odkazy alebo akúkoľvek informáciu ohľadom Addmention, prosím kontaktujte nás priamo na &nbsp;<a href="mailto: kunova@addmention.com">kunova@addmention.com</a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EndView"
}
</script>

<style lang="scss" scoped>
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
</style>